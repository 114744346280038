<template>
    <div class="flex-column container">
        <img src="/img/staking/chest-unactive.png"/>
        <div class="mb-2 flex flex-column">
            Final amount:<br/>
            <span class="text-amount">
                {{ stakeObj.finalAmount | removeDecimals | localFormatNumber }} SCRL
            </span>
        </div>
        <p>
            To end: {{ calculateEndStakingDurationMs(stakeObj.timestamp) | duration('humanize') }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['stakeObj', 'loadingData', 'stakePeriodSecond'],
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        calculateEndStakingDurationMs(startTimestamp) {
            startTimestamp = parseInt(startTimestamp);
            return ((startTimestamp + parseInt(this.stakePeriodSecond)) - Math.floor(Date.now() / 1000)) * 1000;
        },
    }
};
</script>

<style scoped>
div {
    display: inline-flex;
    align-items: center;
    opacity: 0.7;
}

.container {
    /*width: 12rem;*/
    /*box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);*/
    /*border-radius: 5px;*/
    /*background-color: rgba(255, 255, 255, .15);*/
    /*backdrop-filter: blur(5px);*/
}

@media only screen and (max-width: 599px) {
 .container {
    width: 100%;
    margin: auto;
 }
.text-amount{
    font-size: 150%;
}
}

@media (max-width: 1000px) {
    .text-amount {
        font-size: 100%;
    }
}
</style>
