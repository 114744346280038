<template>
    <div class="flex-column container" style="position: relative"
         v-bind:class="displayLoading ? 'chest-loading':''"
         @click="claimClick(stakeObj.id)"
    >
        <img src="/img/staking/chest-opened-get-wizard.png"/>
        <div class="mb-2 flex flex-column">
            Final amount:<br/>
            <span class="text-amount">
                {{ stakeObj.finalAmount | removeDecimals | localFormatNumber }} SCRL
            </span>
        </div>
        <p>
            Click to claim Wizard
        </p>

        <v-progress-circular
            v-if="displayLoading"
            indeterminate
            :size="100"
            color="amber"
            class=""
            style="position: absolute !important; top:40%;left:40%;z-index:100"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    props: ['stakeObj', 'loadingData', 'chestLoading'],
    data() {
        return {}
    },
    created() {
    },
    computed: {
        displayLoading() {
            return this.chestLoading[this.stakeObj.id];
        }
    },
    methods: {
        claimClick(id) {
            if (!this.chestLoading[id]) {
                this.$emit('claim-wizard-event', id)
            }
        }
    }
};
</script>

<style scoped>
div {
    display: inline-flex;
    align-items: center;
}

.chest-loading {
    cursor: progress;
}

.chest-loading img {
    animation: none !important;
    opacity: 0.6;
}

.container {
    /*width: 12rem;*/
    /*box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); */
    /*border-radius: 5px;*/
    /*background-color: rgba(255, 255, 255, .15);*/
    /*backdrop-filter: blur(5px);*/
}

@media only screen and (max-width: 599px) {
 .container {
    width: 100%;
    margin: auto;
 }
.text-amount{
    font-size: 150%;
}
}

@media (max-width: 1000px) {
    .text-amount {
        font-size: 100%;
    }
}
</style>
