var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-column container" }, [
    _c("img", { attrs: { src: "/img/staking/chest-unactive.png" } }),
    _c("div", { staticClass: "mb-2 flex flex-column" }, [
      _vm._v(" Final amount:"),
      _c("br"),
      _c("span", { staticClass: "text-amount" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm._f("localFormatNumber")(
                _vm._f("removeDecimals")(_vm.stakeObj.finalAmount)
              )
            ) +
            " SCRL "
        ),
      ]),
    ]),
    _c("p", [
      _vm._v(
        " To end: " +
          _vm._s(
            _vm._f("duration")(
              _vm.calculateEndStakingDurationMs(_vm.stakeObj.timestamp),
              "humanize"
            )
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }